/*
Piyo - Agency HTML Template
By - Ngetemplates
*/

/*
Table of Content:
- fonts
- general
- button
- navbar
- header title
- about us
- home intro
- what we do
- services
- features
- portfolio
- testimonial
- blog
- blog single
- pagination
- contact
- footer
- responsive
*/

/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500;600;700;800;900%7CPoppins:400,500,600,700,800,900");

/* general */
body {
  background: #fff;
  color: #888;
  font-size: 16px;
  font-weight: 400;
  font-family: "Prompt", sans-serif;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #111;
}

a {
  color: #111;
}

a:hover {
  color: #1783f9;
  text-decoration: none;
  transition: 0.5s ease-out;
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  list-style-type: none;
}

p {
  line-height: 26px;
}

.bg-grey {
  background: #f5fffb;
}

/* button */
.button {
  background: #1783f9;
  padding: 12px 30px;
  font-weight: 500;
  font-size: 15px;
  border-radius: 5px;
  color: #fff;
  letter-spacing: 1px;
  margin-top: 22px;
  display: inline-block;
  border: 2px solid transparent;
}

.button:hover {
  border-color: #1783f9;
  color: #1783f9;
  background: transparent;
  transition: 0.5s ease-out;
}

/* navbar */
.navbar {
  padding: 8px 0 12px;
  transition: 0.5s ease-out;
  background: transparent;
}

.navbar .navbar-brand {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.navbar .navbar-nav li {
  padding: 0 15px;
}

.navbar .navbar-nav li a {
  color: #fff;
  font-weight: 500;
  font-size: 15px;
}

.navbar .navbar-nav li a:last-child {
  padding-right: 0 !important;
}

.navbar .navbar-nav li:last-child {
  padding-right: 0;
}

.navbar .navbar-toggler i {
  color: #fff;
}

.navbar-fixed {
  background: #6f7882;
  transition: 0.5s ease-out;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0);
}

.navbar-fixed .navbar-brand {
  color: #fff;
}

.navbar-fixed .navbar-brand span {
  color: #1783f9;
}

.navbar-fixed .navbar-nav li a {
  color: #fff;
}

.navbar-fixed .navbar-toggler i {
  color: #333;
}

.navbar-transparent {
  background: transparent !important;
}

/* haader title */
.header-title {
  background: url("../image/bg-header.jpg");
  background-position-x: 0%, 0%;
  background-position-y: 0%, 0%;
  background-attachment: scroll, scroll;
  background-size: auto, auto;
  background-position: center;
  background-size: cover;
  padding: 160px 0 100px;
  text-align: center;
}

.header-title h3 {
  color: #fff;
  font-size: 42px;
  font-weight: 600;
}

/* about us */
.about h2 {
  font-weight: 600;
}

.about h2 span {
  color: #1783f9;
}

.about h6 {
  color: #333;
  margin-bottom: 28px;
  margin-top: 10px;
}

/* home intro */
.home-intro {
  background: url("../image/bg-header.jpg");
  background-position-x: 0%, 0%;
  background-position-y: 0%, 0%;
  background-attachment: scroll, scroll;
  background-size: auto, auto;
  background-position: center;
  background-size: cover;
  padding: 180px 0 120px !important;
}
.home-ew {
  width: 150px;
  margin-left: 50px;
}

.home-intro h5 {
  color: #01ef98;
  margin-top: 40px;
}

.home-intro h2 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  margin: 25px 0;
  line-height: 54px;
}

.home-intro h6 {
  color: #ddd;
  line-height: 28px;
  font-size: 17px;
  margin-bottom: 15px;
}

.home-intro img {
  height: auto;
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
  float: right;
  margin-top: 20px;
}

.home-intro .button {
  background: #01ef98;
  color: #fff;
  border-color: #01ef98;
}

.home-intro .button:hover {
  background: transparent;
}

/* section */
.section {
  padding: 100px 0 0 0;
}

.title-section {
  text-align: center;
  margin-bottom: 50px;
}

.title-section h3 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}

.title-section h3 span {
  color: #1783f9;
}

.title-section p {
  /* width: 60%; */
  margin: auto;
  line-height: 30px;
  font-size: 15px;
}

.title-section-two {
  text-align: left;
}

.title-section-two p {
  width: 100%;
}

/* what we do */
.what-we-do .content {
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 30px;
  height: 350px;
  margin-bottom: 40px;
}

.what-we-do .content i {
  font-size: 38px;
  height: 90px;
  width: 90px;
  background: #f5fffb;
  color: #1783f9;
  border-radius: 50%;
  line-height: 90px;
  margin-bottom: 30px;
}

.what-we-do .content h5 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.what-we-do .content a {
  color: #1783f9;
  font-weight: 500;
  text-transform: uppercase;
}

.what-we-do .content:hover {
  background: #86898d;
  transition: 0.5s ease;
  box-shadow: 0 12px 30px -15px rgba(0, 0, 0, 0.2);
  color: #fff;
  cursor: default;
}

.what-we-do .content:hover h5 {
  color: #fff;
}

.what-we-do .content:hover i {
  background: #1783f9;
  color: #fff;
  transition: 0.5s ease;
  box-shadow: 0 12px 30px -15px rgba(0, 0, 0, 0.2);
}

.what-we-do .content:hover a {
  color: #01ef98;
}

/* services */
.services {
  padding: 100px 0 90px;
}

.services .content {
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 12px 30px -15px rgba(0, 0, 0, 0.2);
}

.services .content i {
  font-size: 54px;
  color: #1783f9;
}

.services .content h5 {
  margin: 25px 0 14px;
}

.services .content-center {
  background: #1783f9;
  color: #fff;
}

.services .content-center i {
  color: #fff;
}

.services .content-center h5 {
  color: #fff;
}

/* features */
.features {
  padding: 130px 0;
}

.features .content h3 {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 25px;
}

.features .content .list {
  margin-top: 20px;
}

.features .content .list .icon {
  width: 50px;
  height: 50px;
  color: #fff;
  background: #021230;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  float: left;
  margin-right: 18px;
}

.features .content .list .text {
  overflow: hidden;
}

/*portfolio*/
.portfolio {
  background-color: #fff;
}
.portfolio .row .col-md-4 {
  padding: 15px;
}

.portfolio .portfolio-table {
  width: 60vw;
  /* height: 500px; */
  /* background-color: #aaa; */
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.portfolio .portfolio-table-li {
  width: 33%;
  /* height: 200px; */
  /* background-color: #802b2b; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  margin-bottom: 20px;

  position: relative;
}
.portfolio-table-li-wat {
  border: 1px solid #111;
  height: 150px;
  width: 0%;
}
.portfolio .portfolio-table-li img {
  /* margin: auto; */
  display: block;
  width: 60%;
}
.portfolio .portfolio-table-li h5 {
  margin: 15px 0;
  position: absolute;
  bottom: 0;
}
.portfolio .portfolio-filter-menu {
  margin-bottom: 30px;
  text-align: center;
}

.portfolio .portfolio-filter-menu ul li {
  display: inline-block;
  margin: 0 15px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  letter-spacing: 1px;
  transition: 0.4s ease-in-out;
  background: #021230;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 6px;
}

.portfolio .portfolio-filter-menu ul li span {
  z-index: 99;
  position: relative;
}

.portfolio .portfolio-filter-menu ul li.active {
  color: #fff;
  background: #1783f9;
  box-shadow: 0 12px 30px -15px rgba(0, 0, 0, 0.2);
}

.portfolio .content-image {
  position: relative;
}

.portfolio .content-image:hover .image-overlay {
  opacity: 0.7;
}

.portfolio .content-image:hover .portfolio-caption {
  opacity: 1;
}

.portfolio .content-image img {
  width: 100%;
  border-radius: 3px;
}

.portfolio .content-image .portfolio-caption {
  position: absolute;
  left: 30px;
  right: 30px;
  top: 28%;
  color: #fff;
  text-align: center;
  opacity: 0;
  transition: all 0.5s ease;
}

.portfolio .content-image .portfolio-caption h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .content-image .portfolio-caption .subtitle {
  margin-top: 22px;
}

.portfolio .content-image .portfolio-caption ul li {
  display: inline-block;
  margin: 0 5px;
}

.portfolio .content-image .portfolio-caption i {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
}

.portfolio .content-image .image-overlay {
  background: #021230;
  opacity: 0.5;
  position: absolute;
  width: 88%;
  height: 88%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 3px;
  opacity: 0;
  transition: all 0.5s ease;
  margin: auto;
}

.portfolio .cta {
  background: #021230;
  margin: 14px;
  margin-top: 30px;
  padding: 35px 30px;
}

.portfolio .cta h2 {
  color: #fff;
  position: relative;
  margin-bottom: 0;
  top: 6px;
}

.portfolio .cta .button {
  margin-top: 0;
  float: right;
}

/* testimonial */
.testimonial {
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 160px;
}

.testimonial i {
  font-size: 40px;
  margin-bottom: 32px;
  color: #333;
}

.testimonial .carousel-indicators {
  bottom: -60px;
}

.testimonial .carousel-indicators .active {
  background: #1783f9;
}

.testimonial .carousel-indicators li {
  background: #333;
  border-radius: 50%;
  width: 15px;
  height: 14px;
  border: 0;
  margin: 0 10px;
}

/* blog */
.blog .content {
  border-radius: 6px;
  box-shadow: 0 12px 30px -15px rgba(0, 0, 0, 0.2);
  border: 2px solid #ddd;
  margin-bottom: 30px;
}

.blog .content span {
  position: relative;
  background: #1783f9;
  color: #fff;
  padding: 8px 20px;
  top: -32px;
  border-radius: 0 6px 0 0;
  margin-bottom: -29px;
  left: 0px;
}

.blog .content img {
  border-radius: 3px 3px 0 0;
}

.blog .content-text {
  padding: 30px;
  padding-top: 2px;
}

.blog .content-text h5 {
  line-height: 30px;
}

.blog .content-text a:hover {
  color: #1783f9;
  transition: 0.5s ease-out;
}

/* pagination */
.pagination {
  text-align: center;
  margin: 0 auto;
  display: inherit;
}

.pagination ul li {
  display: inline-block;
  margin: 10px;
}

.pagination ul li a {
  height: 50px;
  width: 50px;
  line-height: 48px;
  text-align: center;
  border: 2px solid #ddd;
  display: block;
  font-size: 18px;
  border-radius: 6px;
}

.pagination ul li .active {
  background: #1783f9;
  color: #fff;
  border-color: #1783f9;
}

/* blog single */
.blog-single .content h5 {
  font-size: 24px;
  line-height: 34px;
}

.blog-single .content li {
  display: inline-block;
  margin-right: 20px;
}

.blog-single .content li i {
  margin-right: 10px;
}

.blog-single .content img {
  margin-top: 30px;
  margin-bottom: 25px;
}

.blog-single .content .quote {
  background: #eee;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 22px;
}

.blog-single .comments {
  border-top: 2px solid #ddd;
  padding-top: 30px;
  margin-top: 35px;
}

.blog-single .comments h5 {
  margin-bottom: 25px;
}

.blog-single .button {
  margin-top: 0;
}

.blog-single .search {
  margin-bottom: 16px;
}

.blog-single .search input {
  width: calc(100% - 65px);
  height: 52px;
}

.blog-single .search .button {
  float: right;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 22px;
  height: 52px;
  line-height: 26px;
  width: 52px;
}

.blog-single .sidebar-link h5 {
  border-bottom: 2px solid #ddd;
  padding-bottom: 14px;
  margin-bottom: 22px;
}

.blog-single .sidebar-link li {
  margin-bottom: 16px;
}

.blog-single .sidebar-link li a {
  font-size: 15px;
}

.blog-single .categories {
  margin-top: 40px;
}

/* contact */
.contact form input,
.contact form textarea {
  border: 2px solid #ddd;
  padding: 15px 20px;
  border-radius: 6px;
  margin-bottom: 30px;
  width: 100%;
  color: #aaa;
}

.contact .button {
  margin-top: 0;
}

.contact li {
  margin-bottom: 25px;
  font-size: 20px;
}

.contact li i {
  margin-right: 28px;
  color: #1783f9;
}

.contact .map {
  position: relative;
  top: -5px;
}

.contact .map i {
  float: left;
  margin-right: 34px;
  margin-top: 10px;
}

.contact .map p {
  overflow: hidden;
  line-height: 42px;
}

::placeholder {
  color: #aaa;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #aaa;
}

::-ms-input-placeholder {
  color: #aaa;
}

form input,
form textarea {
  border: 2px solid #ddd;
  padding: 15px 20px;
  border-radius: 6px;
  margin-bottom: 30px;
  width: 100%;
  color: #aaa;
}

/* footer bottom */
.footer-bottom {
  background: #021230;
  text-align: center;
  color: #eee;
  padding: 20px 0 20px;
}

/* footer */
footer {
  padding: 120px 0 60px;
  background: #021230;
  color: #ddd;
}

footer h5 {
  color: #fff;
  margin-bottom: 25px;
}

footer h6 {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  position: relative;
  top: -8px;
  font-family: "Poppins", sans-serif;
}

footer h6 span {
  color: #1783f9;
}

footer ul li {
  margin-bottom: 15px;
}

footer ul li a {
  color: #ddd;
}

footer ul li a:hover {
  color: #1783f9;
  transition: 0.5s ease-out;
}

footer ul li i {
  margin-right: 25px;
}

footer ul .map {
  line-height: 30px;
}

footer ul .map i {
  float: left;
  margin-right: 34px;
  margin-top: 5px;
}

footer ul .map p {
  overflow: hidden;
}

footer ul li:last-child {
  margin-bottom: 0;
}

/*responsive*/
@media (max-width: 980px) {
  .home-intro img {
    margin-top: 95px;
  }

  .services .content {
    padding: 20px;
  }

  .pricing .content {
    padding: 20px;
  }

  .features img {
    margin-bottom: 30px;
  }

  .latest-blog .content {
    margin-bottom: 30px;
  }

  .latest-blod .content.r-980 {
    margin-bottom: 0;
  }

  .about .col-md-6 {
    max-width: 100%;
    flex: 100%;
  }

  .features .col-md-6 {
    max-width: 100%;
    flex: 100%;
  }
}

@media (max-width: 767px) {
  .latest-blod .content.r-980 {
    margin-bottom: 0;
  }

  .blog-single .search {
    margin-top: 30px;
  }
}

@media (max-width: 640px) {
  .navbar-fixed .navbar-nav li {
    padding: 0;
  }

  .navbar .navbar-nav {
    background: #fff;
    padding: 14px;
  }

  .navbar .navbar-nav li a {
    color: #333;
  }

  .navbar-fixed .navbar-nav {
    padding: 0;
  }

  .services .content {
    margin-bottom: 30px;
  }

  .services .col-sm-12:last-child .content {
    margin-bottom: 0;
  }

  .pricing .content {
    margin-bottom: 30px;
  }

  .pricing .col-sm-12:last-child .content {
    margin-bottom: 0;
  }

  .latest-blod .content.r-980 {
    margin-bottom: 0;
  }

  .blog-single .search {
    margin-top: 30px;
  }

  footer .col-sm-6 {
    margin-top: 50px;
  }
}

@media (max-width: 480px) {
  .navbar .navbar-brand {
    margin-left: 11px;
  }

  .navbar .navbar-nav {
    margin: 11px;
    margin-left: 11px !important;
  }

  .navbar-fixed .navbar-nav {
    padding: 2px;
  }

  .latest-blod .content.r-980 {
    margin-bottom: 0;
  }

  footer .col-12 {
    margin-top: 30px;
  }
}

@media (max-width: 360px) {
  .latest-blod .content.r-980 {
    margin-bottom: 0;
  }
}

/* 知识产权 */
.patent {
  background-color: #ffffff;
}
.patent-table {
  margin: auto;
  width: 70vw;
  /* height: 900px; */
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.patent-table .patent-table-li {
  margin: 30px 0;
  width: 30%;
  /* background-color: #3475a0; */
}
.patent-table .patent-table-li img {
  /* margin: auto; */
  width: 100%;
  /* background-color: #3475a0;
  height: 200px; */
}

/* 客户案例 */
.usercase-bg {
  background-color: #f5fffb;
}
.usercase-table {
  /* background-color: #01ef98; */
  width: 80vw;
  /* height: 100vh; */
  margin: auto;
  overflow: hidden;
}
.usercase-table h3 {
  /* width: 100%; */
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  margin: 35px;
}
.usercase-water-table {
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.usercase-water-table-li {
  width: 30%;
  /* background-color: #dd8d8d; */
  height: 200px;
  margin: 30px 0;
}
.usercase-water-table img {
  width: 100%;
  max-height: 1300px;
}
.usercase-table h5 {
  text-align: center;
  margin: 15px 0;
}
.usercase-interaction-table-li {
  width: 45%;
  /* background-color: #dd8d8d; */
  /* height: 200px; */
  margin: 20px 0;
}
.usercase-bot {
  /* margin-bottom: 30px; */
  /* height: 120vh; */
}

/* 解决方案 */
.solution-table {
  /* height: 110vh; */
  overflow: hidden;
}
.solution-A-table {
  width: 80vw;
  /* background-color: #01ef98; */
  /* height: 1200px; */
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.solution-A-table-li {
  width: 45%;
  max-width: 1300px;
  /* background-color: #1783f9; */
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
.solution-A-table-li img {
  width: 100%;
  max-height: 1300px;
}
.solution-title {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  margin: 35px;
}
.solution-table {
  width: 80vw;
  margin: auto;
}
.solution-table-li {
  display: flex;
  background-color: #fff;
  align-items: center;
  margin-bottom: 10px;
}
.solution-table-li img {
  width: 40%;
}
.solution-table-li div {
  width: 60%;
  margin-left: 20px;
  padding: 40px;
}
.solution-table-li div p {
  font-size: 18px;
  margin-bottom: 3px;
}
